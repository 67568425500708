<template>
  <div>
    <p v-if="doctor.gender || isConnecticut"><strong>{{labels.gender}}</strong>: {{doctor.gender}}</p>
    <p v-if="doctor.languagesSpoken || isConnecticut"><strong>{{labels.spokenLanguage}}</strong>:
      {{formatCommaList(doctor.languagesSpoken)}}
    </p>
    <p v-if="doctor.education || isConnecticut"><strong>{{labels.education}}</strong>:
      {{doctor.education}}
    </p>
    <p v-if="!hideAcceptNewPatient"><strong>{{labels.acceptingNew}}</strong>: {{acceptingNewLabel}}</p>
    <p v-if="doctor.credentialsAndCertifications || isConnecticut"><strong>{{labels.credentials}}</strong>:
      {{doctor.credentialsAndCertifications}}
    </p>
    <p v-if="doctor.licenseNumber || isConnecticut"><strong>{{labels.licenseNumber}}</strong>: {{doctor.licenseNumber}}</p>
    <p v-if="doctor.npiLicenseNumber || isConnecticut"><strong>{{labels.npiNumber}}</strong>: {{doctor.npiLicenseNumber}}</p>
    <p v-if="doctor.culturalCompetencyInd || isConnecticut"><strong>{{labels.culturalCompetency}}</strong>: {{doctor.culturalCompetencyInd}}</p>
    <!-- <p v-if="doctor.emailAddress">{{labels.email}}</strong>: {{doctor.emailAddress}}</p> -->
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { Doctor, Provider } from '@/types'
  import { mapState } from 'vuex'
  import { formatCommaList } from '@/ts/helpers/search-providers.helpers.ts'
  import isConnecticut from '@/mixins/IsConnecticut.ts'

  export default defineComponent({
    mixins: [isConnecticut],
    props: {
      provider: {
        required: true,
        type: Object as () => Provider
      },
      doctor: {
        required: true,
        type: Object as () => Doctor
      },
      hideAcceptNewPatient: {
        default: false,
        type: Boolean
      }
    },
    computed: {
      acceptingNewLabel(): string|null {
        const acceptingNew = this.doctor!.acceptingNewPatients || ''
        return (
          this.$route.params.lang === 'es-us' && 
          acceptingNew.toLowerCase() === 'yes'
        ) ? 
          'Sí' : 
          this.doctor.acceptingNewPatients
      },
      ...mapState({
        labels: (state: any) => {
          return { 
            ...state.globals.microsite.doctorLabels
          }
        }
      })
    },
    methods: {
      formatCommaList
    }
  })
</script>
