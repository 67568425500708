import axios from 'axios'
import { isMock } from '@/../env.config'
import { objectKeysToCamelCase } from '@oodletech/oodle-js/js'
import { service } from '@/ts/service.methods'
import { SpecialOffersServiceResponseMessage, ContentStackHeaders, ServiceFactoryError, SpecialOfferData, SpecialOffer } from '@/types'
import { specialOffersResponseMessageFactory } from '@/ts/service-helpers/services/factories/service-factories/special-offers-response-message.factory'
import { specialOffersFactory } from '@/ts/service-helpers/services/factories/service-factories/special-offer-cards.factory'
import { generateServiceError, handleServiceResponse } from '@/ts/service.locator.response-handling'
import { mockedSpecialOffers } from '@/ts/service-helpers/services/factories/mock-data/service-dependent/special-offer-cards'
import { clearCacheParam } from '@/ts/service-contentstack.methods'
import { csSpecialOffersBaseUrl, csBaseUrl, csSpecialOffersTestBaseUrl, csTestBaseUrl, isTestContentLocation } from '@/site.config'
import { timeStamp } from '@/ts/service-contentstack.methods'

export const ApiKey = 'blt68e7aaf1814966e4'
export const AccessToken = 'blt365fd62af0716caf'

const mockedOffersFound: SpecialOffer = {
  messageCode: 'OFF001',
  defaultMessage: 'OffersFound',
  packageId: '14'
}

function isCacheBusted(): boolean {
  return window.location.href.indexOf(clearCacheParam) > -1
}

function getOfferHeaders(): ContentStackHeaders {
  // Add the Cache-Control header if &clearCache=true is added to the URL
  if (isCacheBusted()) {
    return ({
      headers: {
        api_key: ApiKey,
        authtoken: AccessToken,
        'Cache-Control': 'max-age=0'
      }
    })
  } else {
    return ({
      headers: {
        api_key: ApiKey,
        authtoken: AccessToken,
      }
    })
  }
}

export async function checkForSpecialOffers(subgroupCk): Promise<SpecialOffersServiceResponseMessage|ServiceFactoryError> {
  if (isMock)  return mockedOffersFound as SpecialOffersServiceResponseMessage
  return new Promise((resolve ,reject) => {
    service.get('private/offers', { subgroupCk: subgroupCk })
    .then(res => handleServiceResponse(resolve, reject, res, specialOffersResponseMessageFactory, 'authenticate-user/hasSpecialOffersCheck'))
    .catch(err => generateServiceError(reject, err, 'special-offers.ts/hasSpecialOffersCheck'))
  })
}

export async function getSpecialOffers(packageId: string, lang = 'en-us', test: boolean = false): Promise<SpecialOfferData[]> {
  if (isMock) {
    return mockedSpecialOffers
  } else {
    let memberOffers = await axios.get(`${(test || isTestContentLocation) ? csSpecialOffersTestBaseUrl : csSpecialOffersBaseUrl}/so/packages/${packageId}/${lang}/content.json${timeStamp}`)
      .then(({ data }) => {
        const offerList = data.offers ? data.offers : []
        const offers = offerList.map(o => objectKeysToCamelCase(o))
         return specialOffersFactory(offers)
      })
      .catch((err) => {
        // eslint-disable-next-line
       
        return err
      })

    if (!memberOffers || (memberOffers.length === 0 && packageId !== '1')) {
      memberOffers = await axios.get(`${(test || isTestContentLocation) ? csSpecialOffersTestBaseUrl : csSpecialOffersBaseUrl}/so/packages/1/${lang}/content.json${timeStamp}`, getOfferHeaders())
      .then(({ data }) => {
        const offerList = data.offers ? data.offers : []
        const offers = offerList.map(o => objectKeysToCamelCase(o))
         return specialOffersFactory(offers)
      })
      .catch((err) => {
        // eslint-disable-next-line
        return err
      })
    }
    return memberOffers
  }
}
