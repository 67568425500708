 import axios from 'axios'
import { Services } from '@/types'
import { getServiceUrl } from '@/site.config'

/* Notes:
--------------
- The endpoint should not have a leading slash "/"
- The "params" argument is optional. If not provided, it will create a single default param for lang = "en-us" for English
- The "version" argument is optional. If not provided, it will default to "v1" for Version 1
----------- */

const defaultHeaders = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }

export function getService(endpoint: string, params?: object, version?: string):  Promise<any> {
    return new Promise((resolve, reject): void => {
      const serviceUrl = getServiceUrl(endpoint, params, version)
      axios.get(serviceUrl)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => reject(error))
    })
  }

  export function postService(endpoint: string, payload: any, params?: object, version?: string):  Promise<any> {
    return new Promise((resolve, reject): void => {
      const serviceUrl = getServiceUrl(endpoint, params, version)
      axios.post(serviceUrl, payload, defaultHeaders)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => reject(error))
    })
  }

  export const service: Services = {
    get: getService,
    post: postService
  }
