import { AuthorizedUserParams } from '@/types'
// import { covidToggleOn } from '@/site.config'

const state = {
  tiered: null,
  groupId: null,
  classId: null,
  classPlanId: null,
  framed: false,
  authParams: null as AuthorizedUserParams | null,
  decryptedParams: null as any | null,
  // covidToggleOn: covidToggleOn // master switch to covid logic + UI
}

const getters = {
  framed: state => state.framed as boolean,
  hideNetworkDropdown: (state, getters, rootState): boolean => {
    const networkDDDisabledFromQuery = state.authParams ? state.authParams.networkDDDisabled : 'false' as string
    const networkDDDisabledFromCMS = rootState.globals.microsite ? rootState.globals.microsite.networkDDDisabled : false as boolean
    return (
      networkDDDisabledFromQuery === 'true' ||
      networkDDDisabledFromQuery === 'yes' ||
      networkDDDisabledFromCMS === true
    )
  },
  packageId: state => state.authParams.packageId as string
}

const mutations = {
  setFramed(state): void {
    state.framed = true
  },
  saveAuthParams(state, authParams): void {
    state.authParams = authParams as AuthorizedUserParams
  },
  setNetworkSetId(state, networkSetId): void {
    state.authParams.networkSetId = networkSetId;
  },
  saveDecryptedParams(state, decryptedParams): void {
    state.decryptedParams = decryptedParams
  }
}

const actions = {
  storeAuthParams({ commit }, authParams: AuthorizedUserParams) {
    commit('saveAuthParams', authParams)
  },
  setNetworkSetId({ commit }, networkSetId) {
    commit('setNetworkSetId', networkSetId);
  },
  storeDecryptedParams({ commit}, decryptedParams) {
    commit('saveDecryptedParams', decryptedParams)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
