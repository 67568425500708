import {
  ContentStackHeaders,
  ContentStackPagePayload,
  GlobalDispatchPayload
} from '@/types'
import env from '@/../env.config'
import axios from 'axios'
import { csBaseUrl, csTestBaseUrl, isTestContentLocation } from '@/site.config'
import { defaultLocale as defaultLang } from '@/ts/plugins/i18n'
export const ApiKey: string = import.meta.env.VITE_CONTENTSTACK_API_KEY!
export const AccessToken: string = import.meta.env
  .VITE_CONTENTSTACK_ACCESS_TOKEN!

export const clearCacheParam: string = `clearCache=true`
export const apiVersion: string = 'v3'
export const fallbackUID = 'bltb926430f5c236a9f'
export const fallbackClientId = 'member'
export const contentStackBaseUrl: string = 'https://api.contentstack.io'

export const timeStamp = `?date=${Date.now()}`

export function isCacheBusted(): boolean {
  return window.location.href.indexOf(clearCacheParam) > -1
}

export function getHeaders(): ContentStackHeaders {
  // Add the Cache-Control header if &clearCache=true is added to the URL
  if (isCacheBusted()) {
    return {
      headers: {
        api_key: ApiKey,
        authtoken: AccessToken,
        'Cache-Control': 'max-age=0'
      }
    }
  } else {
    return {
      headers: {
        api_key: ApiKey,
        authtoken: AccessToken
      }
    }
  }
}

export async function getMicrosites(): Promise<any> {
  return await axios
    .get(
      `${contentStackBaseUrl}/${apiVersion}/content_types/globals/entries/?environment=${env}`,
      getHeaders()
    )
    .then(res =>
      res.data.entries.map(entry => {
        return { client_id: entry.client_id }
      })
    )
    .catch(err => err)
}

export async function getresponseMessages(
  lang: string,
  test: boolean = false
): Promise<any> {
  return await axios
    .get(
      `${(test || isTestContentLocation) ? csTestBaseUrl : csBaseUrl}/messages/${
        lang
      }/content.json${timeStamp}`
    )
    .then(({ data }) => {
      return data
    })
    .catch(err => err)
}

export async function getProviderLogoIcons(
  lang: string,
  test: boolean = false
): Promise<any> {
    return await axios
    .get(
      `${(test || isTestContentLocation) ? csTestBaseUrl : csBaseUrl}/logos/${
        lang
      }/content.json${timeStamp}`
    )
    .then(({ data }) => {
      return data
    })
    .catch(err => err)
}

export async function getStackPageByType(
  stackPayLoad: ContentStackPagePayload
): Promise<any> {
  const fallbackStackClientID: string = 'member'
  const chosenLang = stackPayLoad.lang.toLowerCase()
  return queryContentStackPage(stackPayLoad)
    .then(async res => res)
    .catch(async err => {
      // if first attempt at page fetch fails:
      if (defaultLang !== chosenLang) {
        // while language is not default...
        // 1. try fallback language of page specified
        return await queryContentStackPage({
          ...stackPayLoad,
          lang: defaultLang
        })
          .catch(async () => {
            // 2. if still failing, check for member fallback page in chosen language
            return await queryContentStackPage({
              ...stackPayLoad,
              site: fallbackStackClientID
            })
          })
          .catch(async () => {
            // 3. finally, if still failing, try for fallback member page in default language
            return await queryContentStackPage({
              ...stackPayLoad,
              lang: defaultLang,
              site: fallbackStackClientID
            })
          })
      } else if (stackPayLoad.site !== fallbackStackClientID) {
        // check for member fallback page
        return await queryContentStackPage({
          ...stackPayLoad,
          site: fallbackStackClientID
        })
      }
    })
}

async function queryContentStackPage(
  stackPayLoad: ContentStackPagePayload
): Promise<any> {
  const contentType = stackPayLoad.pageType
  const fullFetchableUrl = `${
    (stackPayLoad.test || isTestContentLocation) ? csTestBaseUrl : csBaseUrl
  }/mircosite/${stackPayLoad.site}/page/${contentType}/${
    stackPayLoad.lang
  }/content.json${timeStamp}`
  const page = await axios.get(fullFetchableUrl).then(res => {
    const page: string = res.data
    if (!page) {
      throw {
        errorMessage: 'This page has yet to be created in Contentstack',
        noPage: true
      }
    }
    return page
  })
  return page
}

export async function getMicrositeGlobals(
  payload: GlobalDispatchPayload
): Promise<any> {
  const chosenLang = payload.lang.toLowerCase()
  return getGlobals(payload)
    .then(async res => res)
    .catch(async err => {
      if (defaultLang !== chosenLang) {
        return await getGlobals({ ...payload, lang: defaultLang })
      } else {
        return err
      }
    })
}

function getGlobals(payload: GlobalDispatchPayload): Promise<any> {
  const clientID = payload.clientID
  const url: string = `${(payload.test|| isTestContentLocation) ? csTestBaseUrl : csBaseUrl}/mircosite/${clientID}/global/${payload.lang}/content.json${timeStamp}`
  return axios
    .get(url)
    .then(res => res.data)
    .catch(() => {
      const newLocationParams = import.meta.env.VITE_MOCK_ENV === 'true' ? '/eyemed-provider-locator-dist/#/member/en-us' : '/member/en-us'
      // const newLocationParams = '/#/member/en-us'
      const newLocation = `${window.location.origin}${newLocationParams}`
      window.location.replace(newLocation)
    })
}
