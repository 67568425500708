import { preferredLanguage } from '@/ts/consts'

export const localeOptions = [
    {
        value: 'en-us',
        label: 'English',
      },
      {
        value: 'es-us',
        label: 'Español',
      },
      {
        value: 'zh-cht',
        label: '繁體中文'
      },
      {
        value: 'fr-fr',
        label: 'français'
      }
]

export const locales = localeOptions.map(locale => locale.value)

export const defaultLocale = locales.includes(preferredLanguage) ? preferredLanguage : 'en-us'
