import {defineComponent} from 'vue'
import tinycolor from 'tinycolor2'
import { mapState } from 'vuex'
function darken(color: string): string {
  if (tinycolor(color).isDark()) return color
  return tinycolor(color)
    .darken(10)
    .toString()
}
function createBorderColors(
  color: string
): {
  borderColor: String;
  borderRightColor: String;
  borderLeftColor: String;
  borderTopColor: String;
  borderBottomColor: String;
} {
  return {
    borderColor: color as String,
    borderRightColor: color as String,
    borderLeftColor: color as String,
    borderTopColor: color as String,
    borderBottomColor: color as String
  }
}
export default defineComponent({
  computed: {
    ...mapState({
      globalTheme(state: any) {
        return {
          toggleColor: state.globals.microsite.googleMapTieredProviderIconColor
            ? state.globals.microsite.googleMapTieredProviderIconColor
            : state.globals.microsite.tertiaryColor,
          covidSafeColor: state.globals.microsite.covidSafeColor
            ? state.globals.microsite.covidSafeColor
            : state.globals.microsite.secondaryColor,
          colorCovidSafe: {
            color: `${
              state.globals.microsite.covidSafeColor
                ? state.globals.microsite.covidSafeColor
                : state.globals.microsite.secondaryColor
            } !important` as String
          },
          color: {
            color: `${state.globals.microsite.primaryColor} !important` as String
          },
          colorPrimary: {
            color: `${state.globals.microsite.primaryColor} !important` as String
          },
          colorPrimaryDark: {
            color: `${darken(
              state.globals.microsite.primaryColor
            )} !important` as String
          },
          colorDark: {
            color: `${darken(
              state.globals.microsite.primaryColor
            )} !important` as String
          },
          colorSecondary: {
            color: `${state.globals.microsite.secondaryColor} !important` as String
          },
          colorSecondaryDark: {
            color: `${darken(
              state.globals.microsite.secondaryColor
            )} !important` as String
          },
          colorTertiary: {
            color: `${state.globals.microsite.tertiaryColor} !important` as String
          },
          colorTertiaryDark: {
            color: `${darken(
              state.globals.microsite.tertiaryColor
            )} !important` as String
          },
          colorTieredMapMarker: state.globals.microsite
            .googleMapTieredProviderIconColor
            ? {
                color: `${state.globals.microsite.googleMapTieredProviderIconColor} !important` as String
              }
            : {
                color: `${state.globals.microsite.tertiaryColor} !important` as String
              },
          colorTieredMapMarkerDark: state.globals.microsite
            .googleMapTieredProviderIconColor
            ? {
                color: `${darken(
                  state.globals.microsite.googleMapTieredProviderIconColor
                )} !important` as String
              }
            : {
                color: `${darken(
                  state.globals.microsite.tertiaryColor
                )} !important` as String
              },
          colorForSure: {
            color: `${state.globals.microsite.primaryColor} !important` as String
          },
          colorWhite: {
            color: '#fff !important'
          },
          colorError: {
            color: '#b5055b' as String
          },
          colorSuccess: {
            color: 'steelblue' as String
          },
          colorBang: {
            color: `${state.globals.microsite.primaryColor} !important` as String
          },
          colorSpecialOffer: {
            color: `${state.globals.microsite.specialOfferColor || state.globals.microsite.primaryColor} !important` as String
          },
          backgroundColor: {
            backgroundColor: `${state.globals.microsite.primaryColor} ` as String
          },
          backgroundColorBlack: {
            backgroundColor: '#717272' as String
          },
          backgroundColorWhite: {
            backgroundColor: 'white' as String
          },
          backgroundColorTransparent: {
            backgroundColor: 'transparent' as String
          },
          backgroundColorSecondary: {
            backgroundColor: `${state.globals.microsite.secondaryColor} ` as String
          },
          backgroundColorTertiary: {
            backgroundColor: `${state.globals.microsite.tertiaryColor} ` as String
          },
          backgroundColorTertiaryDark: {
            backgroundColor: `${darken(
              state.globals.microsite.tertiaryColor
            )} ` as String
          },
          backgroundColorError: {
            backgroundColor: 'firebrick' as String
          },
          backgroundColorSuccess: {
            backgroundColor: 'steelblue' as String
          },
          borderColor: {
            ...createBorderColors(state.globals.microsite.primaryColor)
          },
          borderColorPrimary: {
            ...createBorderColors(state.globals.microsite.primaryColor)
          },
          borderColorSecondary: {
            ...createBorderColors(state.globals.microsite.secondaryColor)
          },
          borderColorTertiary: {
            ...createBorderColors(state.globals.microsite.tertiaryColor)
          },
          borderColorError: {
            ...createBorderColors('#b5055b')
          },
          borderTieredMapMarker: state.globals.microsite
            .googleMapTieredProviderIconColor
            ? {
                borderColor: `${state.globals.microsite.googleMapTieredProviderIconColor} !important` as String
              }
            : {
                borderColor: `${state.globals.microsite.tertiaryColor} !important` as String
              },
          fill: {
            fill: `${state.globals.microsite.primaryColor} !important` as String
          },
          fillSecondary: {
            fill: `${state.globals.microsite.secondaryColor} !important` as String
          },
          button: {
            backgroundColor: `${state.globals.microsite.primaryColor}` as String,
            ...createBorderColors(state.globals.microsite.primaryColor),
            color: 'white'
          },
          buttonSecondary: {
            backgroundColor: `${state.globals.microsite.secondaryColor}` as String,
            ...createBorderColors(state.globals.microsite.secondaryColor),
            color: 'white'
          },
          buttonWhite: {
            color: `${state.globals.microsite.primaryColor}` as String,
            backgroundColor: 'transparent'
          },
          buttonWhiteBordered: {
            color: `${state.globals.microsite.primaryColor} ` as String,
            ...createBorderColors(state.globals.microsite.primaryColor),
            backgroundColor: 'transparent ' as String
          },
          buttonWhiteBorderedSecondary: {
            color: `${state.globals.microsite.secondaryColor} ` as String,
            ...createBorderColors(state.globals.microsite.secondaryColor),
            backgroundColor: 'transparent ' as String
          },
          buttonWhiteSecondary: {
            color: `${state.globals.microsite.secondaryColor} ` as String,
            ...createBorderColors('white'),
            backgroundColor: 'white ' as String
          },
          colorBlack: {
            color: '#717272' as String
          }
        }
      }
    })
  },
  methods: {
    darken,
    isSpanish(): boolean {
      return this.$route.params.lang === 'es-us'
    }
  }
})
