<template>
  <span
    class="special-offer-label"
    :style="globalTheme.colorSpecialOffer"
  >
    <app-inline-svg
      :src="specialOfferLabelIcon"
      :style="globalTheme.colorSpecialOffer"
    />
    <span>{{ label ? label : specialOfferLabel }}</span>
  </span>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { mapState } from 'vuex'
  export default defineComponent({
    props: {
      label: {
        required: false,
        type: String
      }
    },
    computed: {
      ...mapState({
        specialOfferLabelIcon(state: any): string {
          return state.globals.microsite.icons.searchResultCards.specialOfferIcon.url
        },
        specialOfferLabel(state: any): string {
          //@ts-ignore
          const spanishLocale = this.$route.params.lang === 'es-us'
          const label = state.globals.microsite.icons.searchResultCards.specialOfferLabel
          if (label) return label
          return spanishLocale ? 'Oferta Especial' : 'Special Offer'
        }
      })
    }
  })
</script>
