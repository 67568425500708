<template>
  <div>
    <div
      container
      class="ecom-container">
      <div
        v-if="!section.benefitsOnline.hideBenefitsSection"
        grid="row wrap">
        <div
          column="xs-12 s-12">
          <h2
            v-if="section.benefitsOnline.title"
            class="moon _text-center _mbs"
            :style="globalTheme.colorBlack"
            aria-hidden="true">
            {{ section.benefitsOnline.title }}
          </h2>
          <nav
            v-if="section.benefitsOnline.externalLinks.length"
            class="box-link-nav retial-boxes _mbm">
            <box-link
              v-for="(link, i) in section.benefitsOnline.externalLinks"
              :key="i"
              :link="link"
            >
              <tiered-provider-label
                v-if="link.tiered && !section.hideTieredProviderBranding"
              />
            </box-link>
          </nav>
        </div>
      </div>
    </div>
    <div container>
      <div
        v-if="!section.lasikProviders.hideLasikSection"
        grid="row wrap">
        <div column="xs-12 s-12">
          <h2
            v-if="section.lasikProviders.title"
            class="moon _text-center _mbs"
            :style="globalTheme.colorBlack"
            aria-hidden="true">
            {{ section.lasikProviders.title }}
          </h2>

          <nav
            v-if="section.lasikProviders.externalLinks.length"
            class="box-link-nav row-boxes lasik-boxes _mbm">
            <lasik-box-link
              v-for="(link, i) in section.lasikProviders.externalLinks"
              :key="i"
              :link="link"
            >
              <tiered-provider-label
                v-if="link.tiered && !section.hideTieredProviderBranding"
              />
            </lasik-box-link>
          </nav>
        </div>
      </div>
    </div>
    <div container>
      <div
        v-if="!section.alliedProviders.hideAlliedSection && hasAlliedProviders"
        grid="row wrap">
        <div column="xs-12 s-12">
          <h2
            v-if="section.alliedProviders.title"
            class="moon _text-center _mbs"
            :style="globalTheme.colorBlack">
            {{ section.alliedProviders.title }}
          </h2>
          <nav
            v-if="externalLinks.length > 0"
            class="box-link-nav retial-boxes _mbmm">
            <box-link
              v-for="(link, i) in externalLinks"
              :key="i"
              :link="link"
            >
              <tiered-provider-label
                v-if="link.tiered && !section.hideTieredProviderBranding"
              />
            </box-link>
          </nav>
        </div>
      </div>
    </div>
    <div
      v-if="isTieredView"
      container
      class="ecom-container">
      <div
        grid="row wrap"
        class="additional-info-blocks">
        <div column="xs-12 s-12">
          <rich-text
            class="bg-offwhite _pts _pbs _plm _prm content-block"
            :html="section.eye360InfoBox"
          />
        </div>
      </div>
    </div>
    <div
      v-else-if="section.infoContent"
      container
      class="ecom-container">
      <div
        grid="row wrap"
        class="additional-info-blocks">
        <div column="xs-12 s-12">
          <rich-text
            class="bg-offwhite _pts _pbs _plm _prm content-block"
            :html="section.infoContent"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { BenefitsOnlineTab } from '@/types'
  import BoxLink from '@molecules/Search/BoxLink.vue'
  import LasikBoxLink from '@molecules/Search/LasikBoxLink.vue'
  import { mapState } from 'vuex'
  export default defineComponent({
    components: {
      BoxLink,
      LasikBoxLink
    },
    props: {
      section: {
        required: true,
        type: Object as () => BenefitsOnlineTab
      },
      hasAlliedProviders: {
        type: Boolean
      },
      alliedLocations: {
        type: Array
      },
    },
    computed: {
      ...mapState({
        tieredProviderLabelIcon(state: any): string {
          return state.globals.microsite.icons.searchResultCards.tieredProviderSvg
            .url
        },
        tieredProviderLabel(state: any): string {
          //@ts-ignore
          const spanishLocale = this.$route.params.lang === 'es-us'
          const label =
            state.globals.microsite.icons.searchResultCards.tieredProviderLabel
          if (label) return label
          return spanishLocale ? 'Proveedor PLUS' : 'PLUS Provider'
        }
      }),

      externalLinks() {
        const links: any[] = []
        if (this.hasAlliedProviders) {
          this.alliedLocations.forEach((alliedLocation: any) => {
            const lowerCaseLocation = alliedLocation.providerName.toLowerCase()
            this.section.alliedProviders.externalLinks.forEach((externalLink: any) => {
              if (lowerCaseLocation.indexOf(externalLink.key) === 0) {
                links.push(externalLink)
              }
            })
          })
        }
        return links
      },
      isTieredView() {
        return this.$route.query.tieredView ? this.$route.query.tieredView === 'true' : false
      }
    }
  })
</script>
