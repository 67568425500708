<template>
  <div
    v-if="page && !loading">
    <app-simple-title
      :section="page.simpleTitle"
      :class="{'center-title': page.tabs.length < 3}"/>
    <tabs
      :current-tab-index="currentTabIndex"
      :tabs="page.tabs"
      @setCurrentTabIndex="setCurrentTabIndex"/>
    <app-section
      class="bg-white search-content-blocks">
      <search-by-location
        v-if="currentTabIndex === 0"
        :id="$changeCase.paramCase(page.tabs[0].title)"
        :framed="framed"
        role="tabpanel"
        :service-error-occurred="serviceErrorOccurred"
        :config-query-params="configQueryParams"
        :auth-user="authUser"
        :hide-network-dropdown="hideNetworks"
        :auth-params="authParams"
        class="fade-in"
        :section="{...page.searchByLocationTab, ...page.common }"
        :networks="networks"
        :county-states="countyStates"
        :current-network="currentNetwork"
        :zip-code="zipCode"
        :geolocation-available="geolocationAvailable"
        @zipInput="updateZip"
        @networkInput="updateNetwork">
      </search-by-location>
      <search-by-doctor
        v-if="currentTabIndex === 1"
        :id="$changeCase.paramCase(page.tabs[1].title)"
        :framed="framed"
        role="tabpanel"
        :service-error-occurred="serviceErrorOccurred"
        :config-query-params="configQueryParams"
        :auth-user="authUser"
        :hide-network-dropdown="hideNetworks"
        :auth-params="authParams"
        class="fade-in"
        :section="{...page.searchByLocationTab, ...page.searchByDoctorTab, ...page.common}"
        :networks="networks"
        :current-network="currentNetwork"
        :zip-code="zipCode"
        :geolocation-available="geolocationAvailable"
        @zipInput="updateZip"
        @networkInput="updateNetwork"
        />
      <template
        v-slot:above-container>
        <benefits-online
          v-if="currentTabIndex === 2 && !page.benefitsOnlineTab.hideTab"
          :id="$changeCase.paramCase(page.tabs[2].title)"
          role="tabpanel"
          class="fade-in"
          :section="page.benefitsOnlineTab"
          :has-allied-providers="hasAlliedProviders"
          :allied-locations="alliedLocations"/>
      </template>
    </app-section>
  </div>
  <app-loader-section
    v-else-if="loading"
    whole-height />
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import AppLoaderSection from '../components/_global/AppLoaderSection.vue'
  import AppSection from '../components/_global/AppSection.vue'
  import { SearchPageLabels, FormattedNetworkList, Option, AuthorizedUserParams, NetworkOption,
           FormattedAlliedList } from '@/types'
  import { searchPagLabelsFactory } from '@/factories/contentstack/search-page.factory'
  import AppSimpleTitle from '@sections/SimpleTitle.vue'
  import Tabs from '@sections/Search/Tabs.vue'
  import SearchByLocation from '@sections/Search/SearchByLocation.vue'
  import SearchByDoctor from '@sections/Search/SearchByDoctor.vue'
  import BenefitsOnline from '@sections/Search/BenefitsOnline.vue'
  import GetPage from '@/mixins/GetPage'
  import { getNetworks } from '@/ts/service-helpers/services/get-networks'
  import { getCounties } from '@/ts/service-helpers/services/get-counties'
  import { alliedSearch } from '@/ts/service-helpers/services/allied-search'
  import { caseInsensetiveQueryKeyMatch } from '@/ts/helpers'
  import { geolocationAvailable } from '@/ts/consts'
  import { isMock } from '@/../env.config'
  import { CountiesResults } from '@/types/services/counties-list'
  const name = 'search'
  function queryValueExists(queryVal: string|undefined|null): boolean {
    return (queryVal !== undefined && queryVal !== null && queryVal !== '')
  }
  export default defineComponent({
    name: name,
    components: {
      AppSimpleTitle,
      Tabs,
      SearchByLocation,
      SearchByDoctor,
      BenefitsOnline,
      AppSection,
      AppLoaderSection
    },
    mixins: [GetPage],
    props: {
      framed: {
        required: true,
        type: Boolean
      }
    },
    data: function() {
      return {
        currentTabIndex: 0,
        loading: true,
        networks: null as Option[]|null,
        currentNetwork: null as null|Option,
        zipCode: isMock ? '45140' : this.$store.state.context.authParams.zip || '' as string,
        geolocationAvailable: geolocationAvailable as boolean,
        authUser: (this as any).authUserCheck() as boolean,
        authParams: (this as any).getAuthParams() as AuthorizedUserParams,
        configQueryParams: {
          acceptingNew: 'Yes',
          mapOpen: 'false'
        },
        serviceErrorOccurred: false as boolean,
        hasAlliedProviders: (this as any).doAlliedSearch() as boolean,
        alliedLocations: null as any[]|null,
        countyStates: null as any[]|null,
        doctorState: null as null|Option,
      }
    },
    computed: {
      hideNetworks(): boolean {
        return this.$store.getters['context/hideNetworkDropdown']
      },
      clientID(): string {
        return (this as any).$store.state.globals.microsite.clientId.toLowerCase()
      },
      selectedLanguage(): string {
        return (this as any).$store.state.locale.languageSelect.value
      },
      localizedRoute(): string {
        return `/${this.clientID}/${this.selectedLanguage}/results`
      }
    },
    watch:{
      selectedLanguage: {
        handler(languageSelect) {
       setTimeout(() => {
        window.location.reload()
       }, 100)
      },
        deep: true
      },
    },
    methods: {
      getAuthParams(): AuthorizedUserParams {
        const query = this.$store.state.context.authParams
        let networkSetId
        // when a user lands on the app with a networkSetId query parameter already provided, that value takes precedence over the value associated with the microsite
        if (this.$store.state.context.authParams.networkSetId) {
          networkSetId = this.$store.state.context.authParams.networkSetId
        }
        else {
          networkSetId = this.$store.state.globals.microsite.networkSetId || caseInsensetiveQueryKeyMatch('networkSetId', query)
        }
        const authParams = {
          ...query.groupId && { groupId: query.groupId as string },
          ...query.classId && { classId: query.classId as string },
          ...query.classPlanId && { classPlanId: query.classPlanId as string },
          ...query.domState && { domState: query.domState as string }
        } as AuthorizedUserParams
        if (queryValueExists(networkSetId)) {
          authParams.networkSetId = networkSetId
          this.$store.dispatch('context/setNetworkSetId', networkSetId)
        }
        return authParams
      },
      getAddressParams(): object {
        const query = this.$route.query
        return {
          ...query.zip && { zip: query.zip as string },
          ...query.state && { state: query.state as string },
          ...query.city && { city: query.city as string }
        }
      },
      setCurrentNetworkFromQuery(givenNetworkOptions: Option[]): Option|null {
        const givenNetwork = this.$route.query.networkId
        if (!givenNetwork) return null
        const newtorkInList = givenNetworkOptions.find(network => network.value === givenNetwork)
        if (!newtorkInList) return null
        return newtorkInList as Option
      },
      authUserCheck(): boolean {
        const query = this.$store.state.context.authParams as AuthorizedUserParams
        return (
          queryValueExists(query.groupId) ||
          queryValueExists(query.classId) ||
          queryValueExists(query.classPlanId) ||
          queryValueExists(query.networkSetId) ||
          queryValueExists(this.$store.state.globals.microsite.networkSetId) ||
          queryValueExists(this.$store.state.context.authParams.domState)
        )
      },
      addressProvided(): boolean {
        const query = this.$route.query
        const authQuery = this.$store.state.context.authParams
        return queryValueExists(query.zip as string|undefined|null) || (queryValueExists(query.search as string|undefined|null) && queryValueExists(authQuery.zip as string|undefined|null))
      },
      setCurrentTabIndex(index: number): void {
        this.currentTabIndex = index
        //@ts-ignore
        this.emitResizeEvent()
      },
      updateZip(val: string):void {
        this.zipCode = val
      },
      updateNetwork(val: Option):void {
        this.currentNetwork = val
        this.doGetCountiesSearch()
      },
      checkForEcomQueryParam(): void {
        const tabQuery = this.$route.query.tabnumber
        if (typeof tabQuery ===  'string') {
          const newIndex = parseInt(tabQuery) - 1
          if (isNaN(newIndex)) {
            return
          }
          if ((this as any).page.benefitsOnlineTab.hideTab) {
            newIndex > 1 ? this.currentTabIndex = 0 : this.currentTabIndex = newIndex
          } else {
            newIndex > 2 ? this.currentTabIndex = 0 : this.currentTabIndex = newIndex
          }
        }
      },
      doAlliedSearch(): void {
        let networkSetId
        const query = this.$store.state.context.authParams
        if (this.$store.state.context.authParams.networkSetId) {
          networkSetId = this.$store.state.context.authParams.networkSetId
        } else {
          networkSetId = this.$store.state.globals.microsite.networkSetId
            || caseInsensetiveQueryKeyMatch('networkSetId', query)
        }
        if (networkSetId) {
          alliedSearch({ networkSetId: networkSetId })
            .then((res): boolean => {
              const alliedLocations = (res as FormattedAlliedList).alliedLocations
              const hasAllied = alliedLocations.length > 0
              this.hasAlliedProviders = hasAllied
              this.alliedLocations = alliedLocations
              return hasAllied
            })
            .catch((): void => {
              this.serviceErrorOccurred = true
            })
            .finally(() => {
              this.loading = false
              //@ts-ignore
              this.emitResizeEvent()
            })
        }
      },
      doGetCountiesSearch() {
        let networkSetId, networkId, groupId, classId, classPlanId
        const query = this.$store.state.context.authParams
        if (this.$store.state.context.authParams.networkSetId) {
          networkSetId = this.$store.state.context.authParams.networkSetId
        } else {
          networkSetId = this.$store.state.globals.microsite.networkSetId
            || caseInsensetiveQueryKeyMatch('networkSetId', query)
        }
        if (this.currentNetwork && this.currentNetwork.value) {
          networkId = this.currentNetwork.value
        } else if (!networkSetId && !(groupId && classId && classPlanId)) {
          networkId = this.networks && this.networks[0].value
        }
        if (query.groupId && query.classId && query.classPlanId) {
          groupId = query.groupId
          classId = query.classId
          classPlanId = query.classPlanId
        }
        if (networkSetId || networkId || (groupId && classId && classPlanId)) {
          getCounties({ networkSetId, networkId, groupId, classId, classPlanId })
            .then((res): void => {
              this.countyStates = (res as CountiesResults).countyState
            })
            .catch((): void => {
              this.serviceErrorOccurred = true
            })
            .finally(() => {
              this.loading = false
              //@ts-ignore
              this.emitResizeEvent()
            })
        }
      }
    },
    async created(): Promise<void> {
      await (this as any).getStackPageByName(name, searchPagLabelsFactory as (page: any) => SearchPageLabels)
      if (!(this as any).page) {
        this.loading = false
        return
      }
      this.checkForEcomQueryParam()
      setTimeout(() => {
        this.doGetCountiesSearch()
      }, 2000)
      getNetworks()
        .then((res): void => {
          const networks = (res as FormattedNetworkList).networks.map((network: NetworkOption) => {
            if (network.notKnown) {
              network.label = (this as any).page.common.doNotKnowNetworkChoice
            }
            return network
          })
          this.networks = networks
          if (isMock) {
            this.currentNetwork = this.networks[0]
            return
          }
          this.currentNetwork = this.setCurrentNetworkFromQuery(networks)
        })
        .catch((): void => {
          this.serviceErrorOccurred = true
        })
        .finally(() => {
          this.loading = false
          //@ts-ignore
          this.emitResizeEvent()
        })
      if (this.addressProvided() && this.authUserCheck()) {
        if(this.$route.query?.search) {
          const query =  {
          ...this.$route.query
          }
          this.$router.push({
            path: this.localizedRoute,
            query: query
          })
        }
        else {
          const query =  {
            ...this.$route.query,
            ...this.getAuthParams(),
            ...this.getAddressParams()
          }
          this.$router.push({
            path: this.localizedRoute,
            query: query
          })
        }
      }
    }
  })
</script>
