<template>
  <app-loader-section
    v-if="loading"
    whole-height />
  <div
    v-else-if="page"
    class="search-results-page"
    role="main">
    <simple-title
      :page="page"
      :current-network-name="currentNetworkName"
      :results-are-filtered="resultsAreFiltered"
      :filtered-results="allProvidersByDistance"
      :filter-values="filterValues"
      :filter-labels="page.filterLabels"/>
    <div
      ref="map_wrap"
      class="map-wrap"
      :class="{open:mapOpen}">
      <app-map
        :key="key"
        v-model="infoWinOpen"
        :open="mapOpen"
        :labels="page.resultCard"
        :providers="truncatedProviderList"
        :current-provider="selectedProvider"
        @setCurrentProvider="setCurrentProvider"/>
    </div>
    <div
      class="themed-strip map-strip"
      :style="globalTheme.borderColor"
    />
    <app-section class="_p0 expand-button-wrap">
      <template v-slot:above-grid>
        <div class="button-wrap map-collapse-button-wrap">
          <button
            class="btn map-collapse-toggle"
            :style="globalTheme.button"
            @click="toggleMap">{{mapToggleLabel}}</button>
        </div>
      </template>
    </app-section>
    <app-section class="_pt0 _pbs search-results-cards">
      <template
        v-if="providersFromInitialSearch"
        v-slot:above-container>
        <actions
          :key="`actions_${filterKey}`"
          v-model="tieredView"
          :page="page"
          :networks="networks"
          :legacy-view="legacyView"
          :filter-options="filterOptions"
          :filters-open="filtersOpen"
          :email-open="emailOpen"
          @toggleFilter="toggleFilter"
          @toggleEmailForm="toggleEmailForm"/>
        <app-collapsible-section
          v-if="getEmailSearchResultsPayload"
          class="_mbs _mts"
          hide-header
          :collapsed="!emailOpen">
          <email-results-form
            :email-search-results-payload="getEmailSearchResultsPayload"
            :submit-label="page.emailFormSubmitLabel || 'Send Search Results'"
            :input-label="page.emailFormInputLabel || 'Recipient Email Address'"
            @closeDrawer="toggleEmailForm(false)"/>
        </app-collapsible-section>
        <app-collapsible-section
          v-if="initialFilterValues && filterValues && filterOptions && networks"
          class="_mbs bg-white search-result-filters"
          hide-header
          :collapsed="!filtersOpen">
          <search-result-filters
            ref="search_result_filters"
            :key="filterKey"
            :filters="filterValues"
            :initial-filters="initialFilterValues"
            :labels="page.filterLabels"
            :brand-dropdown-options="page.brandDropdownOptions"
            :hide-brand-filter="page.hideBrandFilter"
            :options="filterOptions"
            :networks="networks"
            :legacy-view="legacyView"
            :map-open="mapOpen"
            @setFilters="setChosenFilterValues"
            @closeFilter="toggleFilter(false)"/>
        </app-collapsible-section>
      </template>
      <template v-if="providersFromInitialSearch">
        <div
          v-if="page.medicaidInfoBox && page.showMedicaidInfo"
          column="xs-12 s-12"
          class="_mbs hide-print">
          <flash-notification
            key="plus_flash"
            class="plus-flash"
            @close="closePlusProviderFlash">
            <!-- <template v-slot:title>
              <app-inline-svg
                :style="globalTheme.colorTieredMapMarker"
                :src="tieredProviderLabelIcon"
                class="_mrxxs"/>{{page.resultCard.tieredProviderLabel}}</template> -->
            <rich-text :html="page.medicaidInfoBox"></rich-text>
          </flash-notification>
        </div>
        <div
          v-if="page.plusProviderFlashMessage && resultsHaveTieredProvider && !plusProviderFlashClosed"
          column="xs-12 s-12"
          class="_mbs hide-print">
          <flash-notification
            key="plus_flash"
            class="plus-flash"
            @close="closePlusProviderFlash">
            <template v-slot:title>
              <app-inline-svg
                :style="globalTheme.colorTieredMapMarker"
                :src="tieredProviderLabelIcon"
                class="_mrxxs"/>{{page.resultCard.tieredProviderLabel}}</template>
            <rich-text :html="page.plusProviderFlashMessage"></rich-text>
          </flash-notification>
        </div>
        <div
          v-if="hasSpecialOffers && !specialOfferFlashClosed && !hideOfferInfobox"
          column="xs-12 s-12"
          class="_mbs hide-print">
          <flash-notification
            key="so_flash"
            class="so-flash"
            :color="globalTheme.colorSpecialOffer"
            @close="closeSpecialOfferFlash">
            <template v-slot:title>
              <app-inline-svg
                :style="globalTheme.colorSpecialOffer"
                :src="specialOfferLabelIcon"
                class="_mrxxs"/>{{ specialOfferLabel }}</template>
            <rich-text :html="isPrivate ? page.offerInfoPrivate : page.offerInfoPublic"></rich-text>
          </flash-notification>
        </div>
        <div container>
          <div grid="row wrap">
            <div column="xs-12 s-12">
              <div class="flex-wrap search-result-actions">
                <div
                  v-if="isMobile"
                  class="frm-toggle tiered-toggle">
                  <label :for="specialToggleID">{{page.filterLabels.tieredProvidersToggle}}</label>
                  <VueToggles
                    id="toggle_button"
                    v-model="tieredView"
                    :labels="false"
                    :width="50"
                    :height="22"
                    uncheckedBg="lightgrey"
                    checkedBg="rgb(228, 5, 113)"
                    @keyup.enter="tieredView = !tieredView"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="totalPages > 1"
          column="xs-12 s-12">
          <pagination
            v-model="currentPage"
            aria-label="top_pagniation"
            class="_mbs"
            :page-count="totalPages"
            primary
            @clicked="trackPaginationEvent"/>
        </div>
        <div column="xs-12 s-12">
          <search-result-provider-list
            :key="`provider_list_${key}`"
            :page="page"
            :legacy-view="legacyView"
            :provider-list="truncatedProviderList"
            :all-providers-list="allProvidersByDistance"
            :zip-class="searchResults.zipClass"
            :zip-sorting-miles="searchResults.zipSortingMiles"
            aria-live="polite"
            :special-offers="specialOffers"
            @setCurrentProviderFromList="setCurrentProviderFromList"/>
        </div>
        <div
          v-if="totalPages > 1"
          column="xs-12 s-12">
          <pagination
            v-model="currentPage"
            aria-label="bottom_pagniation"
            :page-count="totalPages"
            @clicked="trackPaginationEvent"/>
        </div>
        <div
          v-if="!truncatedProviderList.length"
          column="xs-12 s-12">
          <flash-notification>
            {{page.noResultsFromFiltersMessage}}
            <template v-slot:action>
              <button
                class="btn sm _mts"
                :style="globalTheme.buttonSecondary"
                @click="clearFilters">{{page.filterLabels.clearButton}}
              </button>
            </template>
          </flash-notification>
        </div>
      </template>
      <div
        v-else
        column="xs-12 s-12">
        <response-flash-notification :error-obj="responseMessage ? responseMessage : {defaultMessage: page.noResultsFromFetchMessage}"/>
      </div>
    </app-section>
    <app-section
      v-if="disclaimerToShow"
      class="_pt0 disclaimer">
      <div column="xs-12 s-12">
        <rich-text
          :html="disclaimerToShow"
          class="_p0"/>
        <p v-if="searchResults">{{ lastDisclaimerRefreshLabel }}: {{searchResults.lastRefreshedDate}}</p>
      </div>
    </app-section>
  </div>
</template>
<script lang="ts">
  import {
    ProviderSearchResults,
    ProviderSearchAddressPredictionOrVerificationResults,
    Provider,
    SearchResultsPageLabels,
    ProviderSearchPayload,
    ProviderSearchAddressPredictionOrVerificationPayload,
    ServiceResponseMessage,
    FormattedFilterList,
    FormattedNetworkList,
    Option,
    FilterValues,
    InitialFilterValues,
    DoctorListFilters,
    EmailSearchResultsPayload,
    SpecialOfferData
  } from '@/types'
   import {defineComponent} from 'vue'
  import { mapState } from 'vuex'
  import { eventBus } from '@/main'
  import GetPage from '@/mixins/GetPage'
  import { cloneDeep } from 'lodash'
  import { VueToggles } from "vue-toggles";
  // model contentstack page label data
  import { resultsPagLabelsFactory } from '@/factories/contentstack/results-page-labels.factory'

  // general data modeling helpers
  import { setSearchPayloadFromQuery, setSearchByDoctorAddressPayloadFromQuery, filterProvidersByUserSelection, filterProvidersByDoctorAddress, resultsAreFiltered, weaveProviderLists, createQueryObjectFromSelectedFilterValues, clearQueries, setSingleValFromQuery, setMultiValFromQuery } from '@/ts/helpers'

  // EyeMed service data fetchers
  import { searchProviders, addressPrediction } from '@/ts/service-helpers/services/search-providers'
  import { getFilters } from '@/ts/service-helpers/services/get-filters'
  import { getNetworks } from '@/ts/service-helpers/services/get-networks'
  import { getSpecialOffers } from '@/ts/service-helpers/services/special-offers'

  // components
  import Pagination from '@molecules/Pagination.vue'
  import SearchResultProviderList from '@organisms/Results/SearchResultProviderList.vue'
  import AppMap from '@sections/Results/GoogleMap.vue'
  import SimpleTitle from '@sections/Results/Title.vue'
  import Actions from '@sections/Results/Actions.vue'
  import SearchResultFilters from '@sections/Results/SearchResultFilters.vue'
  import EmailResultsForm from '@organisms/Results/EmailResultsForm.vue'
  import { getdecryptUrl } from '@/ts/service-helpers/services/get-decryptUrl'
  //adobe analytics
  import { trackEvent, trackSearchEvent, trackPageView } from '@/ts/adobe-analytics/index'

  const name = 'results'
  const pageRange = 10
  export default defineComponent({

    name: name,
    components: {
      Pagination,
      AppMap,
      SimpleTitle,
      Actions,
      SearchResultFilters,
      SearchResultProviderList,
      EmailResultsForm,
      VueToggles
     },
    mixins: [GetPage],
    data() {
      return {
        currentNetworkName: '' as string,
        plusProviderFlashClosed: this.$store.state.plusProviderFlashClosed,
        page: null as SearchResultsPageLabels|null,
        currentPage: 1,
        searchResults: null as ProviderSearchResults|null,
        mapOpen: (this.$route.query.mapOpen === 'true') as boolean,
        infoWinOpen: false as boolean,
        currentProvider: null as Provider|null,
        key:0,
        responseMessage: null as null|ServiceResponseMessage,
        tieredView: (this.$route.query.tieredView === 'true') as boolean,
        sortByZipClass: (this.$route.query.sortByZipClass === 'true') as boolean,
        legacyView: false as boolean,
        loading: true as boolean,
        filterOptions: null as FormattedFilterList|null,
        filtersOpen: false as boolean,
        networks: null as Option[]|null,
        filterKey: 0 as number,
        filterValues: null as FilterValues|null,
        initialFilterValues: null as InitialFilterValues|null,
        pageNavigatedFrom: null as null|string|undefined,
        lastDisclaimerRefreshLabel: this.$route.params.lang === 'en-us' ? 'Last Refreshed Date' : 'Fecha de última actualización',
        emailOpen: false as boolean,
        clientID: this.$route.params.clientID,
        specialOffers: {},
        hasSpecialOffers: false,
        isPrivate: this.$store.getters['context/packageId'],
        specialOfferFlashClosed: this.$store.state.specialOfferFlashClosed,
        hideOfferInfobox: false,
        windowWidth: window.innerWidth as number,
        specialToggleID: 'special_toggle',
        isDoctorAddressSearch: (this.$route.query.isDoctorAddressSearch === 'true') as boolean,
        domState: this.$store.state.context.authParams.domState ? this.$store.state.context.authParams.domState : '',
        decryptedSearchString: ''
      }
    },
    computed: {
      selectedLanguage(): any {
        return this.$store.state.locale.languageSelect
      },
      doctorListFilters(): DoctorListFilters {
        return {
          ...(this.filterValues &&
            this.filterValues.languages && {
              languages: this.filterValues.languages
            }),
          ...(this.filterValues &&
            this.filterValues.gender && { gender: this.filterValues.gender }),
          ...(this.filterValues &&
            this.filterValues.culturalCompetency && { culturalCompetency: this.filterValues.culturalCompetency }),
          ...(this.filterValues &&
            this.filterValues.designations && {
              designations: this.filterValues.designations
            })
        }
      },
      ...mapState({
        tieredProviderLabelIcon: (state: any): string => state.globals.microsite.icons.searchResultCards.tieredProviderSvg.url,
        specialOfferLabelIcon: (state: any): string => state.globals.microsite.icons.searchResultCards.specialOfferIcon.url,
        specialOfferLabel: (state: any): string => state.globals.microsite.icons.searchResultCards.specialOfferLabel,
        mapIcons: (state: any): string => state.globals.microsite.icons.mapMarkers
      }),
      providersFromInitialSearch(): boolean {
        if (!this.searchResults) return false
        const indLength = this.searchResults.independentLocations.length
        const retailLength = this.searchResults.retailLocations.length
        const prefLength = this.searchResults.preferredLocations.length
        const zipClassLength = this.searchResults.zipClassSortedLocations.length
        return indLength + retailLength + prefLength + zipClassLength > 0
      },
      allProvidersByDistance(): Provider[] {
        if (!this.searchResults) return []
        let independent = cloneDeep(this.searchResults.independentLocations)
        let retail = cloneDeep(this.searchResults.retailLocations)
        let preferred = cloneDeep(this.searchResults.preferredLocations)
        let zipClass = cloneDeep(this.searchResults.zipClassSortedLocations)
        let addressSearch = this.$route.query?.doctorStreetaddress
        if(this.isDoctorAddressSearch && addressSearch) {
          zipClass = filterProvidersByDoctorAddress(
            zipClass,
            addressSearch
          )
        }
        if (this.filterValues) {
          independent = filterProvidersByUserSelection(
            independent,
            this.filterValues,
            this.doctorListFilters
          )
          retail = filterProvidersByUserSelection(
            retail,
            this.filterValues,
            this.doctorListFilters
          )
          preferred = filterProvidersByUserSelection(
            preferred,
            this.filterValues,
            this.doctorListFilters
          )
          zipClass = filterProvidersByUserSelection(
            zipClass,
            this.filterValues,
            this.doctorListFilters
          )
        }
        // TODO: Need to remove this logic after business approval for EMR:43855
        // const weavedRest = weaveProviderLists(
        //   retail,
        //   independent
        // )
        // if (!this.sortByZipClass) {
        //   const weavedRest = weaveProviderLists(
        //     retail,
        //     independent
        //   )
        //   return preferred.concat(weavedRest)
        // }
        // else {
        //   return preferred.concat(zipClass)
        // }
        return preferred.concat(zipClass)
      },
      truncatedProviderList(): Provider[] {
        const pageRangeStart = ((this.currentPage - 1) * pageRange)
        return this.currentPage === 1 ?
          this.allProvidersByDistance.slice(0, pageRange) :
          this.allProvidersByDistance.slice(pageRangeStart, pageRangeStart + pageRange)
      },
      selectedProvider(): Provider|undefined {
        return this.currentProvider ? this.currentProvider : this.truncatedProviderList[0]
      },
      totalPages(): number {
        return Math.ceil(this.allProvidersByDistance.length/pageRange)
      },
      mapToggleLabel(): string {
        if (!this.page) return ''
        return this.mapOpen ? this.page.map.collapseLabel : this.page.map.expandLabel
      },
      resultsAreFiltered(): boolean {
        if (this.filterValues) {
          const query = createQueryObjectFromSelectedFilterValues(this.filterValues, this.mapOpen)
          return resultsAreFiltered(query)
        } else {
          return false
        }
      },
      resultsHaveTieredProvider(): boolean {
        return this.allProvidersByDistance.find((provider: Provider) => {
          return provider.tieredLocation
        }) !== undefined
      },
      framed(): boolean {
        return this.$store.state.context.framed
      },
      disclaimerToShow(): string|null {
        if (!this.page) return null
        if (this.resultsHaveTieredProvider) {
          return this.page.disclaimer || null
        } else {
          return this.page.nonTieredDisclaimer ? this.page.nonTieredDisclaimer : this.page.disclaimer || null
        }
      },
      getEmailSearchResultsPayload(): EmailSearchResultsPayload|undefined {
        if (!this.filterValues) return
        const filterValues: FilterValues = this.filterValues
        function mapFilterChoices(filterChoices: Option[]|null): string[]|undefined {
          return (filterChoices && filterChoices.length) ? filterChoices.map((filter) => (filter?.value as string)) : undefined
        }
        function singleFilterValueToString(filterChoice: Option|null): string|null|boolean {
          return filterChoice ? filterChoice.value : ''
        }
        function singleFilterValueToArray(filterChoice: Option|null): string[]|undefined {
          return filterChoice && filterChoice.value ? [(filterChoice.value as string)] : undefined
        }
        return {
          clientId: this.clientID,
          searchUsing: this.$route.query.zip ? 'zip' : 'location',
          providerName: this.$route.query.providerName || '',
          networkName: this.currentNetworkName || '',
          zipCode: this.$route.query.zip || '',
          numberOfLocations: this.allProvidersByDistance.slice(0, 20).length.toString(),
          locatorUrl: window.location.href.replace('framed=true', ''),
          filter: {
            ...(mapFilterChoices(filterValues.brands) && { brands: mapFilterChoices(filterValues.brands) }),
            ...(mapFilterChoices(filterValues.services) && { services: mapFilterChoices(filterValues.services) }),
            ...(mapFilterChoices(filterValues.products) && { products: mapFilterChoices(filterValues.products) }),
            // ...(singleFilterValueToArray(filterValues.designations) && { designations: singleFilterValueToArray(filterValues.designations) }),
            // currently any form of designations is breaking the service call with a "400 Bad Request"
            accessibility: singleFilterValueToArray(filterValues.accessibility),
            languages: singleFilterValueToArray(filterValues.languages) ? [...(singleFilterValueToArray(filterValues.languages) as string[]), 'English'] : ['English'],
            gender: singleFilterValueToString(filterValues.gender) as string,
            culturalCompetency: singleFilterValueToString(filterValues.culturalCompetency) as string,
            // healthCompliant: filterValues.covidCompliant ? 'N' : 'Y',
            eveningHours: filterValues.hours ? filterValues.hours.value === 'eveningHours' : false,
            weekendHours: filterValues.hours ? filterValues.hours.value === 'weekendHours' : false,
            networkName: this.currentNetworkName,
            acceptingNewPatients: filterValues.acceptingNew ? 'Y' : 'N',
            locale: `${this.$route.params.lang}`,
          },
          locations: this.allProvidersByDistance.slice(0, 20),
        }
      },
      isMobile(): boolean {
        return this.windowWidth <= 768
      }
    },
    watch: {
      selectedLanguage: {
        handler(languageSelect) {
       setTimeout(() => {
        window.location.reload()
       }, 100)
      },
        deep: true
      },
      tieredView: {
        handler(val: boolean){
            const newQuery = {
            ...clearQueries(this.$route.query),
            ...createQueryObjectFromSelectedFilterValues(this.filterValues!, this.mapOpen)
            }
            newQuery.tieredView = val.toString()
            newQuery.mapOpen = this.mapOpen && !this.framed ? 'true' : 'false' as string
            this.$router.replace({ query: newQuery })
      },
      deep:true
      },
      currentPage: {
        handler(){
        this.key += 1
        this.currentProvider = null
        this.infoWinOpen = false
        eventBus.$emit('iframeResizeEvent')
        },
        deep:true
      }
    },
    methods: {
      setWindowWidth(width: number): void {
        this.windowWidth = width
      },
      trackPaginationEvent(): void {
        this.trackAnalyticsSearchEvent('event-pagination-applied')
      },
      setChosenFilterValues(filterValues: FilterValues) {
        this.currentPage = 1
        this.infoWinOpen = false
        this.filterValues = filterValues
        this.key += 1
        const searchLabel = !this.truncatedProviderList.length ?
          'event-no-providers-match-filters' :
          'event-additional-filters-applied'
        this.trackAnalyticsSearchEvent(searchLabel)
      },
      clearFilters():void {
        this.$refs.search_result_filters.clearFilters()
        this.filtersOpen = false
      },
      toggleFilter(forceToggle?: boolean): void {
        forceToggle !== undefined ? this.filtersOpen = forceToggle : this.filtersOpen = !this.filtersOpen
        this.filterKey +=1
        if (this.filtersOpen) {
          trackPageView('AdvancedSearch')
        }
      },
      toggleEmailForm(forceToggle?: boolean): void {
        forceToggle !== undefined ? this.emailOpen = forceToggle : this.emailOpen = !this.emailOpen
      },
      toggleMap():void {
        this.mapOpen = !this.mapOpen
      },
      setCurrentProvider(provider: Provider):void {
        this.currentProvider = provider
      },
      setCurrentProviderFromList(provider: Provider): void {
        //open infowindow, scroll map and map pan to provider
        this.setCurrentProvider(provider)
        eventBus.$emit('forceMapInfoWindowOpen')
        parent.postMessage('scroll_to_top', '*')
        this.$refs.map_wrap.scrollIntoView({
          block: 'center',
          behavior: 'smooth'
        })
      },
      async getSearchProviders(radius?: string, placeId?: string ): void {
        const storedQuery = this.$store.state.context.authParams
        const routeQuery = this.$route.query?.search ? this.$store.state.context.decryptedParams : this.$route.query

        searchProviders(setSearchPayloadFromQuery({ ...storedQuery, ...routeQuery, clientId: this.clientID, radius: radius, placeId: placeId },routeQuery) as ProviderSearchPayload)
          .then((res) => {
            const response = res as ProviderSearchResults
            this.searchResults = response as ProviderSearchResults
            this.currentNetworkName = response.networkName as string
            this.legacyView = !response.tieredBenefits as boolean
            this.revealPageAndAttachEventListners()
           // this.$store.state.context.authParams.networkSetId = response.networkSetId
            this.$store.dispatch('context/setNetworkSetId', response.networkSetId)

            const newQuery = { search: this.searchResults.nextParams || ''}
            this.$router.replace({ query: newQuery }).catch(err => {});
      
          })
          .catch((serviceError): void => {
            if (serviceError.nextParams) {
              this.$router.replace({ query: { search: serviceError.nextParams} }).catch(err => {});
            }
            if (serviceError.messageCode && serviceError.messageCode.toUpperCase() === 'PRV003') {
              this.trackAnalyticsSearchEvent('event-provider-name-search-failure')
            } else {
              trackEvent('event-provider-search-failure')
            }
            if (serviceError.messageCode || serviceError.defaultMessage) {
              this.renderServiceError(serviceError)
            }
            this.revealPageAndAttachEventListners(false)
          })
      },
      getProviders(): void {
        const routeQuery = this.$route.query?.search ? this.$store.state.context.decryptedParams : this.$route.query
  
        if(this.isDoctorAddressSearch) {
          addressPrediction(setSearchByDoctorAddressPayloadFromQuery({ ...routeQuery, clientId: this.clientID }) as ProviderSearchAddressPredictionOrVerificationPayload)
          .then((res) => {
            const response = res as ProviderSearchAddressPredictionOrVerificationResults
            if(response.geoInfoList && response.geoInfoList.length >= 1 ) {
              response.multipleResultsFound ? this.getSearchProviders('10', response.geoInfoList[0].placeId) : this.getSearchProviders('1', response.geoInfoList[0].placeId)
            }
          })
          .catch((serviceError): void => {
            if (serviceError.messageCode || serviceError.defaultMessage) {
              this.renderServiceError(serviceError)
            }
            this.revealPageAndAttachEventListners(false)
          })
        }
        else  {
          this.getSearchProviders()
        }
      },
      getFilters(): Promise<void> {
        return getFilters()
          .then(res => {
            this.filterOptions = res as FormattedFilterList
          })
          .catch((serviceError): void => this.renderServiceError(serviceError))
      },
      getNetworks(): void {
        getNetworks()
          .then((res): void => {
            (this as any).networks = (res as FormattedNetworkList).networks
            this.filterValues = this.setFilterValues((res as FormattedNetworkList).networks)
            this.initialFilterValues = this.setInitialFilterValues((res as FormattedNetworkList).networks)
          })
          .catch((serviceError): void => this.renderServiceError(serviceError))
      },
      setFilterValues(networks: Option[]): FilterValues {
        //set filter values based on query parameters so users can share URL's to filtered results
        const options = this.filterOptions as FormattedFilterList
        const route = this.$route
        const filters =  {
          ...this.setInitialFilterValues(networks),
          accessibility: setSingleValFromQuery('accessibility', options, route) as null|Option,
          languages: setSingleValFromQuery('languages', options, route) as null|Option,
          hours: setSingleValFromQuery('hours', options, route) as null|Option,
          products: setSingleValFromQuery('products', options, route) as null|Option[],
          gender: setSingleValFromQuery('gender', options, route) as null|Option,
          culturalCompetency: setSingleValFromQuery('culturalCompetency', options, route) as null|Option,
          brands: setSingleValFromQuery('brands', options, route) as null|Option[],
          designations: setSingleValFromQuery('designations', options, route) as null|Option,
          services: setSingleValFromQuery('services', options, route) as null|Option[],
        }
        return filters
      },
      setInitialFilterValues(networks: Option[]): InitialFilterValues {
        //initial filters are a clear, base state to be used when clear filters is called. Network ID is a required to fetch results and can never be set to null from the filters dropdown.
        const options = this.filterOptions as FormattedFilterList
        const route = this.$route
        const filters =  {
          networkId: setSingleValFromQuery('networkId',  options, route, networks) as Option,
          acceptingNew: this.$route.query.acceptingNew === 'No' ? false :  true as boolean,
          sortByZipClass: true,
          accessibility: null,
          // covidCompliant: this.$route.query.covidCompliant === 'Yes' ? true : false as boolean,
          mapOpen: 'false',
          products: null,
          brands: null,
          services: null,
          languages: null,
          hours: null,
          gender: null,
          culturalCompetency: null,
          designations: null,
        }
        return filters
      },
      renderServiceError(serviceError) {
        this.responseMessage = {
          defaultMessage: serviceError.message,
          messageCode:serviceError.messageCode
        }
      },
      revealPageAndAttachEventListners(trackEvents = true){
        this.loading = false
        this.$nextTick(() => {
          if (trackEvents) {
            if (!this.providersFromInitialSearch || !this.truncatedProviderList.length) {
              this.trackAnalyticsSearchEvent('event-no-providers-match-filters')
            } else {
              if (!this.pageNavigatedFrom || this.pageNavigatedFrom.toLowerCase() === 'search') {
                this.trackAnalyticsSearchEvent('event-provider-search-success')
              }
            }
          }
          (this.$refs.map_wrap as HTMLElement).addEventListener('transitionend', (event) => {
            if (event.target === this.$refs.map_wrap) {
              this.key +=1
              //@ts-ignore
              this.emitResizeEvent()
            }
          })
          //@ts-ignore
          this.emitResizeEvent()
        })
      },
      closePlusProviderFlash():void {
        this.$store.commit('closePlusProviderFlash')
      },
      closeSpecialOfferFlash():void {
        this.$store.commit('closeSpecialOfferFlash')
      },
      trackAnalyticsSearchEvent(name: string): void {
        trackSearchEvent(
          name,
          this.filterValues as FilterValues,
          this.truncatedProviderList,
          this.currentPage,
          this.$route.query.zip,
          this.$route.query.providerName,
          this.currentNetworkName
        )
      },
      getSpecialOffers() {
        const hideSpecialOffer = this.$store.state.globals.microsite.hideSpecialOffer || ''
        if (hideSpecialOffer === 'ALL') return
        let packageId = this.$store.getters['context/packageId']
        // If packageId exists but is blank, we are private, but treat like hideSpecialOffer === 'ALL'
        if (packageId === '') {
          return
        }
        // If packageId does not exist at all, we are public, use default packageId of 1
        else if (!packageId) {
          packageId = '1'
        }
        // Otherwise, we are private; use given packageId
        getSpecialOffers(packageId)
          .then((res): void => {
            const offers = {}
            const hideBands = hideSpecialOffer.split(';')
            res.forEach((offer) => {
              const bandDescription = offer.bandDescription
              if (bandDescription) {
                for (const bd of bandDescription) {
                  // skips offer, where domicile state matches with excluded state; given locator is private
                  if(this.isPrivate !== 1  && offer.excludedState && offer.excludedState.length && offer.excludedState.includes(this.domState)){
                    continue;
                  }
                  else {
                    if (bd && !hideBands.includes(bd)) {
                      if (!(bd in offers)) {
                        offers[bd] = []
                      }
                      offers[bd].push(offer)
                    }
                  }
                }
              }
            })
            this.specialOffers = offers
            this.hasSpecialOffers = true
          })
          .catch((serviceError): void => {
            this.renderServiceError(serviceError)
          })
      },
      setHideOfferInfobox() {
        if (this.page) {
          if (this.isPrivate) {
            if (this.page.hideOfferInfoboxPrivate) {
              this.hideOfferInfobox = true
            }
          }
          else {
            if (this.page.hideOfferInfoboxPublic) {
              this.hideOfferInfobox = true
            }
          }
        }
      }
    },
    async beforeRouteEnter (to, from, next): Promise<void> {
      next((vm) => {
        (vm as any).pageNavigatedFrom = from.name
      })
    },
    async created(): Promise<void> {
      await (this as any).getStackPageByName(name, resultsPagLabelsFactory as (page:any) => SearchResultsPageLabels)
      if (!this.page) {
        this.loading = false
        return
      } else {
        await this.getFilters()
        this.getNetworks()
        this.getProviders()
        this.getSpecialOffers()
        this.setHideOfferInfobox()
        
      }
    },
    mounted() {
      eventBus.$on('windowSizeChange', this.setWindowWidth)
    },
    beforeDestroy() {
      eventBus.$off('windowSizeChange', this.setWindowWidth)
    }
  })
</script>
