import {
  ProviderLocationPreference,
  DayLabels,
  HoursObject,
  FormattedHours
} from '@/types'
import { cloneDeep } from 'lodash'

export function createWeekelyHours(
  locationPreference: ProviderLocationPreference,
  dayLabels: DayLabels,
  lang?: string
): FormattedHours[] {

  interface DailyHours {
    day: string
    hours: string | null 
  }

  const hours = [
    { day: dayLabels.monday, hours: locationPreference.monHours },
    { day: dayLabels.tuesday, hours: locationPreference.tueHours },
    { day: dayLabels.wednesday, hours: locationPreference.wedHours },
    { day: dayLabels.thursday, hours: locationPreference.thuHours },
    { day: dayLabels.friday, hours: locationPreference.friHours },
    { day: dayLabels.saturday, hours: locationPreference.satHours },
    { day: dayLabels.sunday, hours: locationPreference.sunHours }
  ] as DailyHours[]

  const conjoinedDailyHours: DailyHours[][] = hours.reduce(
    function(r, currentDay) {
      //@ts-ignore
      if (typeof r.last === 'undefined' || r.last.hours !== currentDay.hours) {
        //@ts-ignore
        r.last = cloneDeep(currentDay)
        r.arr.push([])
      }
      r.arr[r.arr.length - 1].push(currentDay)
      return r
    },
    { arr: [] as DailyHours[][] }
  ).arr

  const formattedHours = conjoinedDailyHours.map((nestedHoursArray: DailyHours[]): FormattedHours => {
    const firstDay = nestedHoursArray[0]
    const lasatDay = nestedHoursArray[nestedHoursArray.length-1]
    let hours = firstDay.hours
    if (hours && lang === 'es-us' && hours.toLowerCase() === 'closed') {
      hours = 'Cerrado'
    }
    return {
      days: nestedHoursArray.length > 1 ? `${firstDay.day} - ${lasatDay.day}` : firstDay.day,
      hours: hours
    }
  })

  return formattedHours
}
