<template>
  <div
    v-if="passes"
    class="provider-sidebar search-result-card appointment-confirmation _p0">
    <h3
      :style="globalTheme.colorBlack"
      class="name _mt0">
      <img
        v-if="brandLogo"
        class="brand-logo"
        :src="brandLogo.url"
        :aria-label="provider.businessName">
      <template v-else>
        {{provider.businessName}}
      </template>
    </h3>
    <provider-address
      hide-link
      :icon="icons.locationSvg.url"
      :provider="provider"
      class="flex-wrap directions" />
    <provider-contact
      :provider="provider"
      :wrap-email="true"
      class="flex-wrap" />
    <div
      v-for="(appt, i) in value"
      :key="i">
      <hr />
      <p
        class="schedule-title"
        :style="appt.failedToSchedule ? globalTheme.colorError : globalTheme.colorSecondaryDark">
        {{appt.patient.firstName}} {{appt.patient.lastName}}
        <font-awesome-icon
          :icon="appt.failedToSchedule ? {prefix: 'far', iconName: 'calendar-times'} : {prefix: 'far', iconName: 'calendar-check'}"
          class="_mla"
        />
      </p>
      <div
        class="themed-strip _mbxs"
        style="position:relative"
        :style="appt.failedToSchedule ? globalTheme.borderColorError : globalTheme.borderColorSecondary" />
      <strong v-if="appt.failedToSchedule">
        <div
          class="_mtxs"
          :style="globalTheme.colorError">{{page.appointmentConfirmation.unknownErrorMessage}}</div>
        <response-message-naked
          v-if="appt.serviceError.messageCode"
          class="_mtxs"
          :error-obj="appt.serviceError"/>
      </strong>
      <!-- <template> -->
        <strong>{{appt.appointment?.prettyDate}}, {{removeLeadingZero(appt.appointment?.availableTime)}}</strong>
        <br />
        <strong>{{langWith}} Dr. {{appt.appointment?.providerFirstName}} {{appt.appointment?.providerLastName}}</strong>
      <!-- </template> -->
    </div>
    <a
      v-if="appointmentsPayload.find(appt => appt.failedToSchedule)"
      style="display:inline-block;"
      class="btn _mts hide-print"
      :href="scheduleHomeRoute.path"
      :style="globalTheme.buttonSecondary"
      @click.prevent="reschedule(scheduleHomeRoute)">{{page.appointmentConfirmation.reschedule}}</a>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { Provider, UserCreatedAppointment, ContentStackImage, ServiceResponseMessage, SchedulePageLabels, CurrentProvider } from '@/types'
  import { mapState } from 'vuex'
  import ScheduleRouterGuard from '@/mixins/ScheduleRouterGuards.ts'  
  import { removeLeadingZero, createAppointment } from '@/ts/helpers/schedule.helpers.ts'
  import { scheduleViewChildRoutes } from '@/router.ts'
  import { eventBus } from '@/main'
  import { trackSchedulingStep } from '@/ts/adobe-analytics/index'

  export default defineComponent({
    name: 'appointment-confirmation',
    mixins: [ScheduleRouterGuard],
    props: {
      value: {
        required: true,
        type: Array as () => UserCreatedAppointment[]
      },
      provider: {
        required: true,
        type: Object as () => CurrentProvider
      },
      localizedRoute: {
        required: true,
        type: String
      },
      page: {
        required: true,
        type: Object as () => SchedulePageLabels
      },
    },
    data() {
      return {
        scheduleHomeRoute: scheduleViewChildRoutes[0],
        passes: false,
        langWith: this.$route.params.lang === 'en-us' ? 'With' : 'Con'
      }
    },
    computed: {
      appointmentsPayload: {
        get(): UserCreatedAppointment[] {
          return (this as any).value
        },
        set(newVal: UserCreatedAppointment[]) {
          (this as any).$emit('input', newVal)
        }
      },
      ...mapState({
        icons: (state: any) => {
          return { 
            ...state.globals.microsite.icons.searchResultCards, 
            ...state.globals.microsite.icons.general,
          }
        },
        brandLogo(state: any) {return state.brandLogos.brandLogos[(this as any).provider.bandId] as ContentStackImage|undefined}
      })
    },
    methods: {
      removeLeadingZero,
      reschedule(route: {name: string, path: string}): void {
        this.appointmentsPayload = this.appointmentsPayload.filter(appt => appt.failedToSchedule)
        const query = this.$route.query
        this.appointmentsPayload.map((appt): void => {
          appt.appointment = null
          appt.lockId = null
          appt.queuedAppointmentId = null
          appt.failedToSchedule = false
          appt.serviceError = undefined
        })
        this.$router.replace({ name: route.name, path: `${this.localizedRoute}/${route.path}`, query: query })
          .then(() => {
            eventBus.$emit('scrollScheduleNavIntoFocus')
            trackSchedulingStep('event-tab-step-started', this.provider)
          })
          .catch(err => err)
      },
      renderServiceError(serviceError): ServiceResponseMessage {
        return { 
          defaultMessage: serviceError.message,
          messageCode:serviceError.messageCode 
        }
      }
    },
    created() {
      const insufficientAppointmentData = this.value.find(user => {
        return !user.appointment || !user.patient.firstName || !user.patient.lastName
      })
      if (insufficientAppointmentData || !this.provider) {
        //@ts-ignore
        this.returnToFirstRoute()
      } else {
        this.passes= true
      }
    }
  })
</script>
