<template>
  <div container>
    <div
      grid="row wrap bottom"
      class="_ptm _pbm">
      <div
        column="xs-12 s-12 m-6 l-4"
        class="_mbs">
        <form-multi-select
          id="language"
          v-model="filterValues.languages"
          :label="labels.language"
          :tooltip-content="labels.languageTooltip"
          :placeholder="chooseLabel(labels.language)"
          :multiple="false"
          :options="options.languages"
          :searchable="true"
          :clear-on-select="true"/>
      </div>
      <div
        column="xs-12 s-12 m-6 l-4"
        class="_mbs">
        <form-multi-select
          id="hours"
          v-model="filterValues.hours"
          :label="labels.hours"
          :tooltip-content="labels.hoursTooltip"
          :placeholder="chooseLabel(labels.hours)"
          :multiple="false"
          :options="options.hours"
          :searchable="true"
          :clear-on-select="true"/>
      </div>
      <div
        column="xs-12 s-12 m-6 l-4"
        class="_mbs">
        <form-multi-select
          id="products"
          v-model="filterValues.products"
          :label="labels.products"
          :tooltip-content="labels.productsTooltip"
          :placeholder="chooseLabel(labels.products)"
          :multiple="true"
          :options="options.products"
          :searchable="true"
          :clear-on-select="false"/>
      </div>
      <div
        column="xs-12 s-12 m-6 l-4"
        class="_mbs">
        <form-multi-select
          id="gender"
          v-model="filterValues.gender"
          :label="labels.gender"
          :tooltip-content="labels.genderTooltip"
          :placeholder="chooseLabel(labels.gender)"
          :multiple="false"
          :options="options.gender"
          :searchable="true"
          :clear-on-select="true"/>
      </div>
      <div
        v-if="!hideNetworks"
        column="xs-12 s-12 m-6 l-4"
        class="_mbs">
        <form-multi-select
          id="network"
          v-model="filterValues.networkId"
          :label="labels.network"
          :tooltip-content="labels.networkTooltip"
          :placeholder="chooseLabel(labels.network)"
          :multiple="false"
          :allow-empty="false"
          :options="networks"
          :searchable="true"
          :clear-on-select="true"/>
      </div>
      <div
        v-if="!hideBrandFilter"
        column="xs-12 s-12 m-6 l-4"
        class="_mbs">
        <form-multi-select
          id="brands"
          v-model="filterValues.brands"
          :label="labels.brands"
          :tooltip-content="labels.brandsTooltip"
          :placeholder="chooseLabel(labels.brands)"
          :multiple="true"
          :options="brandFiltersDropdown"
          :searchable="true"
          :clear-on-select="false"/>
      </div>
      <div
        column="xs-12 s-12 m-6 l-4"
        class="_mbs">
        <form-multi-select
          id="specialty"
          v-model="filterValues.designations"
          :label="labels.specialty"
          :tooltip-content="labels.specialtyTooltip"
          :placeholder="chooseLabel(labels.specialty)"
          :multiple="false"
          :options="options.designations"
          :searchable="true"
          :clear-on-select="true"/>
      </div>
      <div
        column="xs-12 s-12 m-6 l-4"
        class="_mbs">
        <form-multi-select
          id="culturalCompetency"
          v-model="filterValues.culturalCompetency"
          :label="labels.culturalCompetency"
          :placeholder="chooseLabel(labels.culturalCompetency)"
          :multiple="false"
          :options="options.culturalCompetency"
          :searchable="true"
          :clear-on-select="true"/>
      </div>
      <div
        column="xs-12 s-12 m-6 l-4"
        class="_mbs">
        <form-multi-select
          id="services"
          v-model="filterValues.services"
          :label="labels.services"
          :tooltip-content="labels.servicesTooltip"
          :placeholder="chooseLabel(labels.services)"
          :multiple="true"
          :options="options.services"
          :searchable="true"
          :clear-on-select="false"/>
      </div>
      <div
        column="xs-12 s-12 m-6 l-4"
        class="_mbs">
        <form-multi-select
          id="accessibility"
          v-model="filterValues.accessibility"
          :label="labels.accessibility"
          :tooltip-content="labels.accessibilityTooltip"
          :placeholder="chooseLabel(labels.accessibility)"
          :multiple="true"
          :options="options.accessibility"
          :searchable="true"
          :clear-on-select="false"/>
      </div>
      <div
        v-if="!labels.hideAcceptNewPatient"
        column="xs-12 s-12 m-6 l-4"
        class="_mbs"
        :class="{'covid-flex-wrap': covidToggleOn}">
        <div class="flex-wrap" id="accepting_new">
          <form-checkbox
            id="accepting-new"
            class="accetping_new"
            v-model="filterValues.acceptingNew"
            :label="labels.acceptNew"/>
        </div>
        <!-- <div
          v-if="covidToggleOn && !legacyView"
          class="flex-wrap">
          <form-checkbox
            id="covid_compliant"
            v-model="filterValues.covidCompliant"
            :label="labels.covidCompliantToggle"/>
        </div> -->
      </div>
      <div column="xs-12 s-12">
        <div class="button-wrap">
          <button
            class="sm"
            :style="globalTheme.buttonSecondary"
            @click="applyFilters"
            @keydown.enter="applyFilters">{{labels.filterButton}}</button>
          <button
            class="sm"
            :style="globalTheme.buttonWhiteBorderedSecondary"
            @click="clearFilters"
            @keydown.enter="clearFilters">{{labels.clearButton}}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import FormMultiSelect from '@molecules/Form/FormMultiSelect.vue'
  import FormSelect from '@molecules/Form/FormSelect.vue'
  import FormCheckbox from '@molecules/Form/FormCheckbox.vue'
  import { FormattedFilterList, FilterLabels, Option, FilterValues } from '@/types'
  import { getBaseUrl } from '@/site.config.ts'
  import { cloneDeep, isEqual, pickBy } from 'lodash'
  import { eventBus } from '@/main'
  import { _prepareQueryString } from '@/site.config'
  import { createQueryObjectFromSelectedFilterValues, clearQueries } from '@/ts/helpers/search-providers.helpers.ts'
  import { Dictionary } from 'vue-router'
  const publicPath = import.meta.env.VITE_MOCK_ENV === 'true' ? '/eyemed-provider-locator-dist/#' : ''
  export default defineComponent({
    components: {
      FormMultiSelect,FormCheckbox
    },
    props: {
      filters: {
        type: Object as ()=> FilterValues,
        required: true
      },
      initialFilters:{
        type: Object as ()=> FilterValues,
        required: true
      },
      mapOpen: {
        required: true,
        type: Boolean
      },
      labels: {
        required: true,
        type: Object as ()=> FilterLabels
      },
      options: {
        required: true,
        type: Object as () => FormattedFilterList
      },
      networks: {
        required: true,
        type: Array as () => Option[]
      },
      legacyView: {
        type: Boolean,
        default: false
      },
      brandDropdownOptions: {
        required: true,
        type: Array as () => string[]
      },
      hideBrandFilter: {
        required: true,
        type: Boolean,
      }
    },
    data() {
      return {
        filterValues: cloneDeep(this.filters),
        acceptingNew: true,
        covidToggleOn: this.$store.state.context.covidToggleOn,
        tags:'tags',
      }
    },
    computed: {
      hideNetworks(): boolean {
        return this.$store.getters['context/hideNetworkDropdown']
      },
      brandFiltersDropdown(): Option[] {
        if (!this.brandDropdownOptions.length) return []
        return this.brandDropdownOptions.map(
          (brandDropdownOption: any): Option => {
            return {
              value: brandDropdownOption,
              label: brandDropdownOption
            }
          }
        )
      }
    },
    watch: {
      filterValues:{
        handler(val,newval){
        }
      },
      products: {
        handler: function(){
          eventBus.$emit('iframeResizeEvent')
        },
        deep: true
      },
      services: {
        handler: function(){
          eventBus.$emit('iframeResizeEvent')
        },
        deep: true
      },
      mapOpen:{
        handler(newVal){
        const mapOpenQueryVal = newVal === true ?  'true' : 'false'
        const query = this.setNewQueries()
        query.mapOpen = mapOpenQueryVal
        const newURL =  _prepareQueryString(`${publicPath}${getBaseUrl(this.$route.params)}/results`, query)
        history.replaceState({}, '', newURL)
        },
        deep:true
      }
    },
    methods: {
      chooseLabel(label: string): string {
        return `${this.labels.choose} ${label?.toLowerCase()}`
      },
      closeFilter(): void {
        this.$emit('closeFilter')
      },
      setMultiValFromQuery(someKey: string): Option[]|null {
        const valueFromQuery = this.$route.query[someKey] as string
        if (!valueFromQuery) return null
        const allValues = valueFromQuery.split(',')
        return allValues.map((value: string) => {
          return this.options[someKey].find((option: Option) => option.value === value)
        })
      },
      setSingleValFromQuery(someKey: string, optionalFindSet: Option[]): Option|null {
        const valueFromQuery = this.$route.query[someKey] as string
        if (!valueFromQuery) return null
        if (optionalFindSet) {
          return optionalFindSet.find((option: Option) => option.value === valueFromQuery) || null
        } else {
          return this.options[someKey].find((option: Option) => option.value === valueFromQuery)
        }
      },
      setNewQueries(): Dictionary<string> {
        return {
          ...clearQueries(this.$route.query),
          ...createQueryObjectFromSelectedFilterValues(this.filterValues, this.mapOpen)
        }
      },
      setInitialQueries(): Dictionary<string> {
        return {
          ...clearQueries(this.$route.query),
          ...this.initialFilters.networkId && { networkId: this.initialFilters.networkId.value as string },
          mapOpen: this.mapOpen ? 'true' : 'false'
        }
      },
      applyFilters(): void {
        const query = this.setNewQueries() as Dictionary<string>
        this.checkForNetworkChangeThenUpdate(query, this.filterValues)
      },
      clearFilters(): void {
        const query = this.setInitialQueries() as Dictionary<string>
        this.checkForNetworkChangeThenUpdate(query, this.initialFilters)
      },
      checkForNetworkChangeThenUpdate(query: Dictionary<string>, filterValues: FilterValues): void {
        const networkIdQuery = this.$route.query.networkId ? this.$route.query.networkId  : null
        const networkIdFilter = this.filterValues.networkId ? this.filterValues.networkId.value : null
        if (networkIdFilter !== networkIdQuery) {
          // A change in network ID demands a re-fetch, so we push the router with the new query to trigger that
          this.$router.replace({
            path: `${getBaseUrl(this.$route.params)}/results`,
            query: query
          })
            .then(() => {
              try { // get the loader in view when iframed
                window.scrollTo(0, 0)
                window.parent.parent.scrollTo(0, 0)
              } catch {
                parent.postMessage('snap_to_top', '*')
              }
            })
        } else {
          this.setFilterValuesAndUpdateUrlString(query, filterValues)
        }
      },
      setFilterValuesAndUpdateUrlString(query: Dictionary<string>, filterValues: FilterValues): void {
        // pickBy() prunes an object of null or undefined properties, and isEqual() does a deep comparison of two objects. By using these in conjuction, we can check to verify that our filters are indeed different before emitting a change event and re-rendring stuff up in the parent component
        const filterValuesAreUpdated = !isEqual(pickBy(filterValues),pickBy(this.filters))
        if (filterValuesAreUpdated) {
          const newURL =  _prepareQueryString(`${publicPath}${getBaseUrl(this.$route.params)}/results`, query)
          history.replaceState({}, '', newURL)
          this.$emit('setFilters', filterValues)
        }
        this.closeFilter()
      },
      // setNewCovidValue(covidToggle: boolean) {
      //   this.filterValues.covidCompliant = covidToggle
      //   this.applyFilters()
      // }
    },
    // beforeDestroy() {
    //   eventBus.$off('covidToggleFromActions', this.setNewCovidValue)
    // }
  })
</script>
