import 'regenerator-runtime/runtime'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store/index'
import '@/ts/app-globals'
import '@/ts/plugins/vee-validate'
import Theme from '@/mixins/Theme'
import '@/scss/application.scss'
import { addAdobeDigitalData } from '@/ts/adobe-analytics/index.ts'
import { createApp } from 'vue'
import emitter from 'tiny-emitter/instance'
import addGlobalComponenets from './ts/app-globals'
import addGlobalValidationComponenets from './ts/plugins/vee-validate'
import VueGoogleMaps from 'vue-google-maps-community-fork'
import { windowParams } from '../env.config'
import VCalendar from 'v-calendar';

addAdobeDigitalData()

export const eventBus = {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args)
}

const lang = windowParams.split('/')[2]
const app = createApp(App)
app.mixin(Theme)
app.use(router)
app.use(store)
app.use(VCalendar)
app.use(VueGoogleMaps, {
  load: {
    key: import.meta.env.VITE_GM_API_KEY,
    language: (lang && lang.toLowerCase() === 'es-us') ? 'es-419' : lang,
    region: 'US'
  },
})


addGlobalComponenets(app)
addGlobalValidationComponenets(app)


router.isReady().then(() => app.mount('#app'))
// app.mount('#app')


